import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type StatusType = 'error' | 'unknown' | 'success' | '';
export interface DialogData {
  status: StatusType;
  title: string;
  content: string;
  label: string;
  showStatusIcon: boolean;
}

@Component({
  selector: 'app-info-dialog',
  template:
    '<app-popup [showStatusIcon]="data.showStatusIcon" [status]="data.status" [title]="data.title" ' +
    '[content]="data.content" [label]="data.label"></app-popup>',
})
export class InfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
